import React from 'react'

const Footer = () => {
    return (
            <footer className="">
                <p className="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
                    <span className="float-md-left d-block d-md-inline-block">Copyright © 2024 <a className="text-bold-800 grey darken-2" href="/"  target="_blank" rel="noopener noreferrer" > </a>, All rights reserved. </span>
                    {/* <span className="float-md-right d-block d-md-inline-blockd-none d-lg-block">Hand-crafted &amp; Made with <i className="ft-heart pink" /></span> */}
                </p>
            </footer>
    
    )
}

export default Footer