import {useEffect, useState} from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getApiWithToken } from "../../Helper/helper";
import BaseUrl from '../../baseurl/BaseUrl';
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";


const Ssingledata = () => {
    const navigate = new useNavigate();
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem("token")) {
          navigate("/Login");
        } else {
          // setShow2(true);
        }
        window.scrollTo(0, 0);
      
        getData(); // Assuming getData is a function defined outside useEffect
        // getDatas();
        // statusll("pending")
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    const [userData,] = useState(localStorage.getItem("token"));
    const [searchParams] = useSearchParams();
      const id = searchParams.get("id");

     

    //   const [personal_detail, setpersonal_detail]=useState()
      

     
      const [about_us, setabout_us]=useState([])
      console.log(about_us);
      
      
      
      
      


      const getData = () => {
        setLoader(true)
        
        getApiWithToken(`${BaseUrl.baseUrl}admin/surrogate/${id}`, "",userData)
               .then(({ data }) => {
              setabout_us(data?.user)
              console.log(data?.user,"-----abo");
              setLoader(false)
            
             
              
             
              
            //   setdes(data.data[0].description)
            //   console.log(data.data[0].description, 'des')
            //   setname(data.data[0].name)
            //   setimg(data.data[0].img)
            //   setType(data.data[0].Type)
            //   setweight(data.data[0].weight)
            //   setheight(data.data[0].height)
            //   setfamily(data.data[0].family)
            //   setdisease(data.data[0].disease)
            //   settraining(data.data[0].training) 
            //   setvideos(data.data[0].breedvideo)  
                          
            //   set(data.data.Type)
              
            
             
            //   setdata(data.data)
              // setid(data.id);
              // setName(data.name);
              // setEmail(data.email);
              // //    
              // setProfileImage(data?.picture?data?.picture:[{
              //   picture:null
              // }] )
             
            })
            .catch((err) => {
              console.log("err---------", err);
            });
        };
    return (
        <>
            {loader ? <Loader fullpage loading /> : null}
            <Header />

            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                            <h3 className="content-header-title mb-0 d-inline-block">Surrogate</h3>
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        {/* <li className="breadcrumb-item"><a href="index.html">Home</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#">Form Layouts</a>
                                        </li> */}
                                        <li className="breadcrumb-item active">Surrogate
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="content-header-right col-md-6 col-12">
                            <div className="dropdown float-md-right">
                                {/* <button className="btn btn-danger dropdown-toggle round btn-glow px-2" id="dropdownBreadcrumbButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button> */}
                                {/* <div className="dropdown-menu" aria-labelledby="dropdownBreadcrumbButton"><a className="dropdown-item" href="#"><i className="la la-calendar-check-o" /> Calender</a>
                                    <a className="dropdown-item" href="#"><i className="la la-cart-plus" /> Cart</a>
                                    <a className="dropdown-item" href="#"><i className="la la-life-ring" /> Support</a>
                                    <div className="dropdown-divider" /><a className="dropdown-item" href="#"><i className="la la-cog" /> Settings</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        {/* Basic form layout section start */}
                        <section id="basic-form-layouts">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title" id="bordered-layout-basic-form">Pregnancy History</h4>
                                            {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3" /></a> */}
                                            {/* <div className="heading-elements">
                                                <ul className="list-inline mb-0">
                                                    <li><a data-action="collapse"><i className="ft-minus" /></a></li>
                                                    <li><a data-action="reload"><i className="ft-rotate-cw" /></a></li>
                                                    <li><a data-action="expand"><i className="ft-maximize" /></a></li>
                                                    <li><a data-action="close"><i className="ft-x" /></a></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        <div className="card-content collpase show">
                                            <div className="card-body">
                                                <div className='row'>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>First Name:</b> {about_us?.first_name}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Last Name:</b> {about_us?.last_name}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Email:</b> {about_us?.email}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Phone Number:</b> {about_us?.phone_number}
                                                </div>
                                                {/* <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>delivery:</b> {about_us?.delivery}
                                                </div> */}
                                               
                                               
                                                    {/* <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>About:</b> {about_us?.about}
                                                </div> */}
                                                
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>US Citizen:</b> {about_us?.us_citizen}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>State:</b> {about_us?.state}
                                                </div>
                                               
                                            

                                                {/* <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Health Information:</b> {about_us?.health_information}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Health Information Detail:</b> {about_us?.health_information_detail}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px', boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}} >
                                                <b>health insurance:</b> {about_us?.health_insurance}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>children into the world:</b> {children_into_the_world}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>children require medical attention:</b> {children_require_medical_attention}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Currently Breastfeeding:</b> {currently_breastfeeding}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Doctor Ordered Bed Rest:</b> {doctor_ordered_bed_rest}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>miscarriage:</b> {miscarriage}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Name:</b> {name}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Surrogate Before:</b> {surrogate_before}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Termination:</b> {termination}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Typical Mood Like During Pregnancy:</b> {typical_mood_like_during_pregnancy}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Undergo Fertility Treatment or Medical Assistance:</b> {undergo_fertility_treatment_or_medical_assistance}
                                                </div> */}
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>signature:</b> {about_us?.signature}
                                                </div> */}
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>state:</b>  {about_us?.state}
                                                </div>
                                                {/* <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>surrogacy:</b>{about_us?.surrogacy}
                                                </div> */}
                                                <div className='col-lg-12'>
                                                <h1>Personal Detail</h1>
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>About Us:</b> {about_us?.personal_detail?.about_us}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Age:</b> {about_us?.personal_detail?.age}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>bmi:</b> {about_us?.personal_detail?.bmi}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>city:</b> {about_us?.personal_detail?.city}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Contact Email Address:</b>{about_us?.personal_detail?.contact_email_address}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>convicted of a felony:</b> {about_us?.personal_detail?.convicted_of_a_felony}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>country born in</b> {about_us?.personal_detail?.country_born_in}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>county:</b> {about_us?.personal_detail?.county}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>date of birth:</b> {about_us?.personal_detail?.date_of_birth}
                                                </div> 
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>driver license or id card:</b>{about_us?.personal_detail?.driver_license_or_id_card}
                                                </div> 
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>emergency first name:</b> {about_us?.personal_detail?.emergency_first_name}
                                                </div> 
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>emergency last name:</b> {about_us?.personal_detail?.emergency_last_name}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Emergency Phone Number:</b>  {about_us?.personal_detail?.emergency_phone_number}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>ethnic background:</b> {about_us?.personal_detail?.ethnic_background}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>financially stable:</b>{about_us?.personal_detail?.financially_stable}
                                                </div>
                                                <div className='col-lg-6' style={{marginTop:'10px',boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>height feet:</b>{about_us?.personal_detail?.height_feet}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>height inches:</b> {about_us?.personal_detail?.height_inches}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>last six month traveled:</b> {about_us?.personal_detail?.last_six_month_traveled}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>legal citizanship status:</b> {about_us?.personal_detail?.legal_citizanship_status}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>number of csection:</b> {about_us?.personal_detail?.number_of_csection}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>number of deliveries:</b>{about_us?.personal_detail?.number_of_deliveries}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>travell next 18 months:</b>{about_us?.personal_detail?.travell_next_18_months}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>uterine malformation:</b> {about_us?.personal_detail?.uterine_malformation}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>weight:</b> {about_us?.personal_detail?.weight}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>zipcode:</b> {about_us?.personal_detail?.zipcode}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>relationship_status:</b>  {about_us?.personal_detail?.relationship_status}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>reliable_transportation:</b> {about_us?.personal_detail?.reliable_transportation}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>sexual_orientation:</b> {about_us?.personal_detail?.sexual_orientation}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>sexually_activity:</b>  {about_us?.personal_detail?.sexually_activity}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>social_security_number:</b>{about_us?.personal_detail?.social_security_number}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>street_address:</b> {about_us?.personal_detail?.street_address}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>surrogate:</b> {about_us?.personal_detail?.surrogate}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>pregnancy_history:</b>{about_us?.personal_detail?.personal_history}
                                                </div>

                                                <div className='col-lg-12'>
                                                <h1>personal_history</h1>

                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>high school diploma or ged:</b>{about_us?.personal_history?.high_school_diploma_or_ged}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Bankruptcy:</b>{about_us?.personal_history?.bankruptcy}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Divorce or legal separation:</b>{about_us?.personal_history?.divorce_or_legal_separation}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Been arrested:</b>{about_us?.personal_history?.been_arrested}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>been charged with a dui:</b>{about_us?.personal_history?.been_charged_with_a_dui}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>government child protective agency:</b>{about_us?.personal_history?.government_child_protective_agency}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>adoption:</b>{about_us?.personal_history?.adoption}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>substance abuse program:</b>{about_us?.personal_history?.substance_abuse_program}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>domestic violence:</b>{about_us?.personal_history?.domestic_violence}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>involved with a lawsuit:</b>{about_us?.personal_history?.involved_with_a_lawsuit}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>past due on child support:</b>{about_us?.personal_history?.past_due_on_child_support}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>lost custody of any of your children:</b>{about_us?.personal_history?.lost_custody_of_any_of_your_children}
                                                </div>
                                                <div className='col-lg-12'>
                                                <h1>health_insurance</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>Military or reserves:</b>{about_us?.health_insurance?.military_or_reserves}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>current employment status:</b>{about_us?.health_insurance?.current_employment_status}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>shortterm disability benefits:</b>{about_us?.health_insurance?.shortterm_disability_benefits}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>medical health insurance:</b>{about_us?.health_insurance?.medical_health_insurance}
                                                </div>
                                                <div className='col-lg-12'>
                                                <h1>health_information</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>blood type:</b>{about_us?.health_information?.blood_type}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>current birth control method:</b>{about_us?.health_information?.current_birth_control_method}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>recent pap smear:</b>{about_us?.health_information?.recent_pap_smear}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>abnormal pap smear:</b>{about_us?.health_information?.abnormal_pap_smear}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>secondhand smoke at home or at work:</b>{about_us?.health_information?.secondhand_smoke_at_home_or_at_work}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>health_concerns:</b>{about_us?.health_information?.health_concerns}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>prescription medications:</b>{about_us?.health_information?.prescription_medications}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>allergic_to_anything:</b>{about_us?.health_information?.allergic_to_anything}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>type_of_doctor:</b>{about_us?.health_information?.type_of_doctor}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>labor_and_delivery:</b>{about_us?.health_information?.labor_and_delivery}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>eating_disorder:</b>{about_us?.health_information?.eating_disorder}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>psychiatrist_therapist_or_psychologist:</b>{about_us?.health_information?.psychiatrist_therapist_or_psychologist}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>psychiatric_reasons:</b>{about_us?.health_information?.psychiatric_reasons}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>physically_emotionally_or_sexually_abused:</b>{about_us?.health_information?.physically_emotionally_or_sexually_abused}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>chlamydia_gonorrhea_pelvic_inflammatory_disease:</b>{about_us?.health_information?.chlamydia_gonorrhea_pelvic_inflammatory_disease}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hiv_or_aids:</b>{about_us?.health_information?.hiv_or_aids}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hepatitis_b:</b>{about_us?.health_information?.hepatitis_b}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hepatitis_b_ivf_clinic:</b>{about_us?.health_information?.hepatitis_b_ivf_clinic}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hepatitis_a_hepatitis_b_or_hepatitis_c:</b>{about_us?.health_information?.hepatitis_a_hepatitis_b_or_hepatitis_c}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>covid_19_vaccination:</b>{about_us?.health_information?.covid_19_vaccination}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>menstrual_cycles:</b>{about_us?.health_information?.menstrual_cycles}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>not_pregnant_alcoholic_beverages:</b>{about_us?.health_information?.not_pregnant_alcoholic_beverages}
                                                </div>
                                                <div className='col-lg-12'>
                                                <h1>health_information_detail</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>add_or_adhd:</b>{about_us?.health_information_detail?.add_or_adhd}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>anemia:</b>{about_us?.health_information_detail?.anemia}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>anxiety:</b>{about_us?.health_information_detail?.anxiety}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>arthritis:</b>{about_us?.health_information_detail?.arthritis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>asthma:</b>{about_us?.health_information_detail?.asthma}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>bipolar_disorder:</b>{about_us?.health_information_detail?.bipolar_disorder}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>birth_deformities:</b>{about_us?.health_information_detail?.birth_deformities}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>cancer:</b>{about_us?.health_information_detail?.cancer}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>cerebral_palsy:</b>{about_us?.health_information_detail?.cerebral_palsy}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>chronic_bronchitis:</b>{about_us?.health_information_detail?.chronic_bronchitis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>cystic_fibrosis:</b>{about_us?.health_information_detail?.cystic_fibrosis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>deafness_hard_of_hearing:</b>{about_us?.health_information_detail?.deafness_hard_of_hearing}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>depression:</b>{about_us?.health_information_detail?.depression}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>diabetes:</b>{about_us?.health_information_detail?.diabetes}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>emphysema:</b>{about_us?.health_information_detail?.emphysema}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>endometriosis:</b>{about_us?.health_information_detail?.endometriosis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>epilepsy_or_convulsions:</b>{about_us?.health_information_detail?.epilepsy_or_convulsions}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>eye_or_retinal_disease:</b>{about_us?.health_information_detail?.eye_or_retinal_disease}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hpv:</b>{about_us?.health_information_detail?.hpv}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>head_injury:</b>{about_us?.health_information_detail?.head_injury}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>heart_problems:</b>{about_us?.health_information_detail?.heart_problems}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hemophilia:</b>{about_us?.health_information_detail?.hemophilia}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hemorrhaging_after_birth:</b>{about_us?.health_information_detail?.hemorrhaging_after_birth}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>herpes:</b>{about_us?.health_information_detail?.herpes}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>high_cholesterol:</b>{about_us?.health_information_detail?.high_cholesterol}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>blood_pressure_hypertension:</b>{about_us?.health_information_detail?.blood_pressure_hypertension}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hysterectomy:</b>{about_us?.health_information_detail?.hysterectomy}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>intrauterine_fibroids:</b>{about_us?.health_information_detail?.intrauterine_fibroids}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>kidney_problems:</b>{about_us?.health_information_detail?.kidney_problems}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>learning_disability:</b>{about_us?.health_information_detail?.learning_disability}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>liver_disease:</b>{about_us?.health_information_detail?.liver_disease}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>lung_disease:</b>{about_us?.health_information_detail?.lung_disease}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>mental_illness:</b>{about_us?.health_information_detail?.mental_illness}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>mental_retardation:</b>{about_us?.health_information_detail?.mental_retardation}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>migraines:</b>{about_us?.health_information_detail?.migraines}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>multiple_sclerosis:</b>{about_us?.health_information_detail?.multiple_sclerosis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>muscular_dystrophy:</b>{about_us?.health_information_detail?.muscular_dystrophy}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>neck_or_back_problem:</b>{about_us?.health_information_detail?.neck_or_back_problem}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>nervous_system_problems:</b>{about_us?.health_information_detail?.nervous_system_problems}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>obesity:</b>{about_us?.health_information_detail?.obesity}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>paralysis:</b>{about_us?.health_information_detail?.paralysis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>personalitydisorder:</b>{about_us?.health_information_detail?.personalitydisorder}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>postpartum_depression:</b>{about_us?.health_information_detail?.postpartum_depression}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>pre_eclampsia:</b>{about_us?.health_information_detail?.pre_eclampsia}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>pre_term_labor:</b>{about_us?.health_information_detail?.pre_term_labor}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>schizophrenia:</b>{about_us?.health_information_detail?.schizophrenia}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>seizures:</b>{about_us?.health_information_detail?.seizures}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>sickle_cell_trait:</b>{about_us?.health_information_detail?.sickle_cell_trait}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>skin_disorders:</b>{about_us?.health_information_detail?.skin_disorders}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>speech_problems:</b>{about_us?.health_information_detail?.speech_problems}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>spina_bifida</b>{about_us?.health_information_detail?.spina_bifida}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>syphilis</b>{about_us?.health_information_detail?.syphilis}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>tb_or_exposed_to_tb</b>{about_us?.health_information_detail?.tb_or_exposed_to_tb}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>tay_sachs</b>{about_us?.health_information_detail?.tay_sachs}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>thyroid_problems</b>{about_us?.health_information_detail?.thyroid_problems}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>ulcers</b>{about_us?.health_information_detail?.ulcers}
                                                </div>
                                                <div className='col-lg-12'>

                                                    <h1>pregnancy_history</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>ulcers:</b>{about_us?.pregnancy_history?.doctor_ordered_bed_rest}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>

                                                <b>undergo_fertility_treatment_or_medical_assistance:</b>{about_us?.pregnancy_history?.undergo_fertility_treatment_or_medical_assistance}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>children_require_medical_attention:</b>{about_us?.pregnancy_history?.children_require_medical_attention}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>miscarriage:</b>{about_us?.pregnancy_history?.miscarriage}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>termination:</b>{about_us?.pregnancy_history?.termination}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>surrogate_before:</b>{about_us?.pregnancy_history?.surrogate_before}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>currently_breastfeeding:</b>{about_us?.pregnancy_history?.currently_breastfeeding}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>typical_mood_like_during_pregnancy:</b>{about_us?.pregnancy_history?.typical_mood_like_during_pregnancy}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>children_into_the_world:</b>{about_us?.pregnancy_history?.children_into_the_world}
                                                </div>
                                                <div className='col-lg-12'>
                                                    <h1>pregnancy_experiance</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>name:</b>{about_us?.pregnancy_experiance?.name}
                                                </div>
                                                <div className='col-lg-12'>
                                                    <h1>delivery</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>total_dilveries:</b>{about_us?.delivery?.total_dilveries}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>many_c_sections:</b>{about_us?.delivery?.many_c_sections}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>many_c_sections:</b>{about_us?.delivery?.many_c_sections}
                                                </div>
                                                <div className='col-lg-12'>

                                                    <h1>about</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>many_c_sections:</b>{about_us?.about?.languages_read_write}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>personality_character:</b>{about_us?.about?.personality_character}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>about_yourself:</b>{about_us?.about?.about_yourself}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>hobbies_interest:</b>{about_us?.about?.hobbies_interest}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>pamper_yourself:</b>{about_us?.about?.pamper_yourself}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>together_family:</b>{about_us?.about?.together_family}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>childcare_children:</b>{about_us?.about?.childcare_children}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>vacation_in_the_future:</b>{about_us?.about?.vacation_in_the_future}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>any_pets:</b>{about_us?.about?.any_pets}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>surrogacy_journey:</b>{about_us?.about?.surrogacy_journey}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>favorite_restaurants:</b>{about_us?.about?.favorite_restaurants}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>eat_out:</b>{about_us?.about?.eat_out}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>favorite_color:</b>{about_us?.about?.favorite_color}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>favorite_sport:</b>{about_us?.about?.favorite_sport}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>favorite_animal:</b>{about_us?.about?.favorite_animal}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>music_genre:</b>{about_us?.about?.music_genre}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>favorite_flowers:</b>{about_us?.about?.favorite_flowers}
                                                </div>
                                                <div className='col-lg-12'>
                                                    <h1>signature</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>medical_record:</b>{about_us?.signature?.medical_record}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>criminal_background:</b>{about_us?.signature?.criminal_background}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>photo_usage:</b>{about_us?.signature?.photo_usage}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>agree_to_provide_additional_information:</b>{about_us?.signature?.agree_to_provide_additional_information}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>accuracy_provided_information:</b>{about_us?.signature?.accuracy_provided_information}
                                                </div>
                                               


                                                <div className='col-lg-12'>
                                                    <h1>surrogacy</h1>
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>id:</b>{about_us?.surrogacy?.id}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>user_id:</b>{about_us?.surrogacy?.user_id}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'}}>
                                                <b>advised_medical_professional:</b>{about_us?.surrogacy?.advised_medical_professional}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>alcohol_drugs_smoking:</b>{about_us?.surrogacy?.alcohol_drugs_smoking}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>applicable_tattoos_piercings:</b>{about_us?.surrogacy?.applicable_tattoos_piercings}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>babies_willing_carry:</b>{about_us?.surrogacy?.babies_willing_carry}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>baby_will_not_survive:</b>{about_us?.surrogacy?.baby_will_not_survive}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>cosmetic_procedures:</b>{about_us?.surrogacy?.cosmetic_procedures}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>delivery_room_with_you:</b>{about_us?.surrogacy?.delivery_room_with_you}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>diagnosed_down_syndrome:</b>{about_us?.surrogacy?.diagnosed_down_syndrome}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>drug_alcohol_nicotine:</b>{about_us?.surrogacy?.drug_alcohol_nicotine}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>embryos_transferred:</b>{about_us?.surrogacy?.embryos_transferred}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>great_candidate:</b>{about_us?.surrogacy?.great_candidate}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>drug_alcohol_nicotine:</b>{about_us?.surrogacy?.drug_alcohol_nicotine}
                                                </div>
                                                   <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>injections_ivf_doctor:</b>{about_us?.surrogacy?.injections_ivf_doctor}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>ivf_clinic_journey:</b>{about_us?.surrogacy?.ivf_clinic_journey}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>kind_of_relationship:</b>{about_us?.surrogacy?.kind_of_relationship}
                                                </div>

                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>scheduling_appointment_journey:</b>{about_us?.surrogacy?.scheduling_appointment_journey}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>poor_communication_surrogate:</b>{about_us?.surrogacy?.poor_communication_surrogate}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>embryos_transferred:</b>{about_us?.surrogacy?.embryos_transferred}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>splits_into_identical_twins:</b>{about_us?.surrogacy?.splits_into_identical_twins}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>triplets_to_twins_for_medical_reasons:</b>{about_us?.surrogacy?.triplets_to_twins_for_medical_reasons}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>twins_singleton_for_medical_reasons:</b>{about_us?.surrogacy?.twins_singleton_for_medical_reasons}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>life_is_at_risk:</b>{about_us?.surrogacy?.life_is_at_risk}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>medical_reason_abnormality:</b>{about_us?.surrogacy?.medical_reason_abnormality}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'40PX'}}>
                                                <b>long_distance_ivf_treatment:</b>{about_us?.surrogacy?.long_distance_ivf_treatment}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'70PX'}}>
                                                <b>advised_medical_professional:</b>{about_us?.surrogacy?.advised_medical_professional}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>pumping_expressing_breast_milk:</b>{about_us?.surrogacy?.pumping_expressing_breast_milk}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>little_personal_note:</b>{about_us?.surrogacy?.little_personal_note}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>created_at:</b>{about_us?.surrogacy?.created_at}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>updated_at:</b>{about_us?.surrogacy?.updated_at}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>files:</b>{about_us?.surrogacy?.files}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>intended_parent_willing:</b>{about_us?.surrogacy?.intended_parent_willing}
                                                </div>
                                                <div className='col-lg-6' style={{boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', height:'30PX'}}>
                                                <b>intended_parent_baby:</b>{about_us?.surrogacy?.intended_parent_baby}
                                                </div>

                                                </div>
                                               </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                {/* <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title" id="bordered-layout-icons">Timesheet</h4>
                                            <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3" /></a>
                                            <div className="heading-elements">
                                                <ul className="list-inline mb-0">
                                                    <li><a data-action="collapse"><i className="ft-minus" /></a></li>
                                                    <li><a data-action="reload"><i className="ft-rotate-cw" /></a></li>
                                                    <li><a data-action="expand"><i className="ft-maximize" /></a></li>
                                                    <li><a data-action="close"><i className="ft-x" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-content collpase show">
                                            <div className="card-body">
                                                <div className="card-text">
                                                    <p>Add <code>.form-bordered</code> to form tag to add border to
                                                        a form-group. In this example icons are used with form controls
                                                        to show the bordered form functionality.</p>
                                                </div>
                                                <form className="form form-horizontal form-bordered">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput1">Employee Name</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="text" id="timesheetinput1" className="form-control" placeholder="employee name" name="employeename" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-user" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput2">Project Name</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="text" id="timesheetinput2" className="form-control" placeholder="project name" name="projectname" />
                                                                    <div className="form-control-position">
                                                                        <i className="la la-briefcase" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput3">Date</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="date" id="timesheetinput3" className="form-control" name="date" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-message-square" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control">Rate Per Hour</label>
                                                            <div className="col-md-9">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">$</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" placeholder="Rate Per Hour" aria-label="Amount (to the nearest dollar)" name="rateperhour" />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">.00</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput5">Start Time</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="time" id="timesheetinput5" className="form-control" name="starttime" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-clock" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput6">End Time</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="time" id="timesheetinput6" className="form-control" name="endtime" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-clock" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row last">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput7">Notes</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <textarea id="timesheetinput7" rows={5} className="form-control" name="notes" placeholder="notes" defaultValue={""} />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-file" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-actions right">
                                                        <button type="button" className="btn btn-warning mr-1">
                                                            <i className="ft-x" /> Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            <i className="la la-check-square-o" /> Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="row justify-content-md-center">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title" id="bordered-layout-card-center">Event Registration</h4>
                                            <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3" /></a>
                                            <div className="heading-elements">
                                                <ul className="list-inline mb-0">
                                                    <li><a data-action="collapse"><i className="ft-minus" /></a></li>
                                                    <li><a data-action="reload"><i className="ft-rotate-cw" /></a></li>
                                                    <li><a data-action="expand"><i className="ft-maximize" /></a></li>
                                                    <li><a data-action="close"><i className="ft-x" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-content collpase show">
                                            <div className="card-body">
                                                <div className="card-text">
                                                    <p>Add <code>.form-bordered</code> to form tag to add border to
                                                        a form-group. In this example centered card is used to show
                                                        the bordered form functionality.</p>
                                                </div>
                                                <form className="form form-horizontal form-bordered">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput1">Full Name</label>
                                                            <div className="col-md-9">
                                                                <input type="text" id="eventRegInput1" className="form-control" placeholder="name" name="fullname" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput2">Title</label>
                                                            <div className="col-md-9">
                                                                <input type="text" id="eventRegInput2" className="form-control" placeholder="title" name="title" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput3">Company</label>
                                                            <div className="col-md-9">
                                                                <input type="text" id="eventRegInput3" className="form-control" placeholder="company" name="company" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput4">Email</label>
                                                            <div className="col-md-9">
                                                                <input type="email" id="eventRegInput4" className="form-control" placeholder="email" name="email" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput5">Contact Number</label>
                                                            <div className="col-md-9">
                                                                <input type="tel" id="eventRegInput5" className="form-control" name="contact" placeholder="contact number" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row last">
                                                            <label className="col-md-3 label-control">Existing Customer</label>
                                                            <div className="col-md-9">
                                                                <div className="input-group col-md-9">
                                                                    <label className="d-inline-block custom-control custom-radio ml-1">
                                                                        <input type="radio" name="customer" className="custom-control-input" />
                                                                        <span className="custom-control-indicator" />
                                                                        <span className="custom-control-description ml-0">Yes</span>
                                                                    </label>
                                                                    <label className="d-inline-block custom-control custom-radio">
                                                                        <input type="radio" name="customer" defaultChecked className="custom-control-input" />
                                                                        <span className="custom-control-indicator" />
                                                                        <span className="custom-control-description ml-0">No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-actions center">
                                                        <button type="button" className="btn btn-warning mr-1">
                                                            <i className="ft-x" /> Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            <i className="la la-check-square-o" /> Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </section>
                        {/* // Basic form layout section end */}
                    </div>
                </div>
            </div>

            <Footer />

        </>


    )
}

export default Ssingledata