import {useEffect, useState} from 'react'
import Footer from '../../layout/Footer'
import Header from '../../layout/Header'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getApiWithToken } from "../../Helper/helper";
import BaseUrl from '../../baseurl/BaseUrl';
import { Loader } from "react-overlay-loader";
import "react-overlay-loader/styles.css";


const Psingledata = () => {
    const navigate = new useNavigate();
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        if (!localStorage.getItem("token")) {
          navigate("/Login");
        } else {
          // setShow2(true);
        }
        window.scrollTo(0, 0);
      
        getData(); // Assuming getData is a function defined outside useEffect
        // getDatas();
        // statusll("pending")
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    const [userData,] = useState(localStorage.getItem("token"));
    const [searchParams] = useSearchParams();
      const id = searchParams.get("id");

     

    //   const [personal_detail, setpersonal_detail]=useState()
      

     
      const [about_us, setabout_us]=useState("")
      
      
      
      
      


      const getData = () => {
        setLoader(true)
        
        getApiWithToken(`${BaseUrl.baseUrl}admin/become/parent/${id}`, "",userData)
               .then(({ data }) => {
                console.log(data.become_parent, 'min')
              setabout_us(data.become_parent)
              setLoader(false)
             
              
             
              
            //   setdes(data.data[0].description)
            //   console.log(data.data[0].description, 'des')
            //   setname(data.data[0].name)
            //   setimg(data.data[0].img)
            //   setType(data.data[0].Type)
            //   setweight(data.data[0].weight)
            //   setheight(data.data[0].height)
            //   setfamily(data.data[0].family)
            //   setdisease(data.data[0].disease)
            //   settraining(data.data[0].training) 
            //   setvideos(data.data[0].breedvideo)  
                          
            //   set(data.data.Type)
              
            
             
            //   setdata(data.data)
              // setid(data.id);
              // setName(data.name);
              // setEmail(data.email);
              // //    
              // setProfileImage(data?.picture?data?.picture:[{
              //   picture:null
              // }] )
             
            })
            .catch((err) => {
              console.log("err---------", err);
            });
        };
    return (
        <>
            {loader ? <Loader fullpage loading /> : null}
            <Header />

            <div className="app-content content">
                <div className="content-wrapper">
                    <div className="content-header row">
                        <div className="content-header-left col-md-6 col-12 mb-2 breadcrumb-new">
                            <h3 className="content-header-title mb-0 d-inline-block">INTENDED PARENT</h3>
                            <div className="row breadcrumbs-top d-inline-block">
                                <div className="breadcrumb-wrapper col-12">
                                    <ol className="breadcrumb">
                                        {/* <li className="breadcrumb-item"><a href="index.html">Home</a>
                                        </li>
                                        <li className="breadcrumb-item"><a href="#">Form Layouts</a>
                                        </li> */}
                                        {/* <li className="breadcrumb-item active">Surrogate
                                        </li> */}
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="content-header-right col-md-6 col-12">
                            <div className="dropdown float-md-right">
                                {/* <button className="btn btn-danger dropdown-toggle round btn-glow px-2" id="dropdownBreadcrumbButton" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Actions</button> */}
                                {/* <div className="dropdown-menu" aria-labelledby="dropdownBreadcrumbButton"><a className="dropdown-item" href="#"><i className="la la-calendar-check-o" /> Calender</a>
                                    <a className="dropdown-item" href="#"><i className="la la-cart-plus" /> Cart</a>
                                    <a className="dropdown-item" href="#"><i className="la la-life-ring" /> Support</a>
                                    <div className="dropdown-divider" /><a className="dropdown-item" href="#"><i className="la la-cog" /> Settings</a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="content-body">
                        {/* Basic form layout section start */}
                        <section id="basic-form-layouts">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title" id="bordered-layout-basic-form">INTENDED PARENT</h4>
                                            {/* <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3" /></a> */}
                                            {/* <div className="heading-elements">
                                                <ul className="list-inline mb-0">
                                                    <li><a data-action="collapse"><i className="ft-minus" /></a></li>
                                                    <li><a data-action="reload"><i className="ft-rotate-cw" /></a></li>
                                                    <li><a data-action="expand"><i className="ft-maximize" /></a></li>
                                                    <li><a data-action="close"><i className="ft-x" /></a></li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        <div className="card-content collpase show">
                                            <div className="card-body">
                                                <div className='row'>
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}
                                                >
                                                <b>Parent1 First Name:</b> {about_us?.parent1_first_name}
                                                </div>
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}
                                                >
                                                <b>Parent1 Last Name:</b> {about_us?.parent1_last_name}
                                                </div>
                                                <div className='col-lg-6' style={{ height: '36px',paddingTop: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
}}
>
                                                <b>Parent2 First Name:</b> {about_us?.parent2_first_name}
                                                </div>
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}
                                                >
                                                <b>Parent2 Last Name:</b> {about_us?.parent2_last_name}
                                                </div>
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}>
                                                <b>phone:</b> {about_us?.phone}
                                                </div>
                                               
                                               
                                                    <div className='col-lg-6' 
                                                    style={{ height: '36px',paddingTop: '10px',
                                                    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                                }}>
                                                <b>Referred By Detail:</b> {about_us?.referred_by_detail}
                                                </div>
                                                
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}
                                                >
                                                <b>Referred By Someone:</b> {about_us?.referred_by_someone}
                                                </div>
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}
                                                >
                                                <b>clinic name:</b> {about_us?.clinic_name}
                                                </div>
                                               
                                            

                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}>
                                                <b>country:</b> {about_us?.country}
                                                </div>
                                                <div className='col-lg-6' 
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }}>
                                                <b>Email:</b> {about_us?.email}
                                                </div>
                                                <div className='col-lg-6'
                                                style={{ height: '36px',paddingTop: '10px',
                                                boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
                                            }} >
                                                <b>Embryos Status:</b> {about_us?.embryos_status}
                                                </div>
                                                
                                                <div className='col-lg-6' style={{ height: '36px',paddingTop: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
}}>
                                                <b>ivf clinic:</b> {about_us?.ivf_clinic}
                                                </div>
                                                <div className='col-lg-6' style={{ height: '36px',paddingTop: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px'
}}>
                                                <b>Journey Readliness:</b>  {about_us?.journey_readliness}
                                                </div>
                                               
                                                </div>
                                               </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                {/* <div className="col-md-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title" id="bordered-layout-icons">Timesheet</h4>
                                            <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3" /></a>
                                            <div className="heading-elements">
                                                <ul className="list-inline mb-0">
                                                    <li><a data-action="collapse"><i className="ft-minus" /></a></li>
                                                    <li><a data-action="reload"><i className="ft-rotate-cw" /></a></li>
                                                    <li><a data-action="expand"><i className="ft-maximize" /></a></li>
                                                    <li><a data-action="close"><i className="ft-x" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-content collpase show">
                                            <div className="card-body">
                                                <div className="card-text">
                                                    <p>Add <code>.form-bordered</code> to form tag to add border to
                                                        a form-group. In this example icons are used with form controls
                                                        to show the bordered form functionality.</p>
                                                </div>
                                                <form className="form form-horizontal form-bordered">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput1">Employee Name</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="text" id="timesheetinput1" className="form-control" placeholder="employee name" name="employeename" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-user" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput2">Project Name</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="text" id="timesheetinput2" className="form-control" placeholder="project name" name="projectname" />
                                                                    <div className="form-control-position">
                                                                        <i className="la la-briefcase" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput3">Date</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="date" id="timesheetinput3" className="form-control" name="date" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-message-square" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control">Rate Per Hour</label>
                                                            <div className="col-md-9">
                                                                <div className="input-group">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">$</span>
                                                                    </div>
                                                                    <input type="text" className="form-control" placeholder="Rate Per Hour" aria-label="Amount (to the nearest dollar)" name="rateperhour" />
                                                                    <div className="input-group-append">
                                                                        <span className="input-group-text">.00</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput5">Start Time</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="time" id="timesheetinput5" className="form-control" name="starttime" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-clock" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput6">End Time</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <input type="time" id="timesheetinput6" className="form-control" name="endtime" />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-clock" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group row last">
                                                            <label className="col-md-3 label-control" htmlFor="timesheetinput7">Notes</label>
                                                            <div className="col-md-9">
                                                                <div className="position-relative has-icon-left">
                                                                    <textarea id="timesheetinput7" rows={5} className="form-control" name="notes" placeholder="notes" defaultValue={""} />
                                                                    <div className="form-control-position">
                                                                        <i className="ft-file" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-actions right">
                                                        <button type="button" className="btn btn-warning mr-1">
                                                            <i className="ft-x" /> Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            <i className="la la-check-square-o" /> Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {/* <div className="row justify-content-md-center">
                                <div className="col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <h4 className="card-title" id="bordered-layout-card-center">Event Registration</h4>
                                            <a className="heading-elements-toggle"><i className="la la-ellipsis-v font-medium-3" /></a>
                                            <div className="heading-elements">
                                                <ul className="list-inline mb-0">
                                                    <li><a data-action="collapse"><i className="ft-minus" /></a></li>
                                                    <li><a data-action="reload"><i className="ft-rotate-cw" /></a></li>
                                                    <li><a data-action="expand"><i className="ft-maximize" /></a></li>
                                                    <li><a data-action="close"><i className="ft-x" /></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-content collpase show">
                                            <div className="card-body">
                                                <div className="card-text">
                                                    <p>Add <code>.form-bordered</code> to form tag to add border to
                                                        a form-group. In this example centered card is used to show
                                                        the bordered form functionality.</p>
                                                </div>
                                                <form className="form form-horizontal form-bordered">
                                                    <div className="form-body">
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput1">Full Name</label>
                                                            <div className="col-md-9">
                                                                <input type="text" id="eventRegInput1" className="form-control" placeholder="name" name="fullname" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput2">Title</label>
                                                            <div className="col-md-9">
                                                                <input type="text" id="eventRegInput2" className="form-control" placeholder="title" name="title" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput3">Company</label>
                                                            <div className="col-md-9">
                                                                <input type="text" id="eventRegInput3" className="form-control" placeholder="company" name="company" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput4">Email</label>
                                                            <div className="col-md-9">
                                                                <input type="email" id="eventRegInput4" className="form-control" placeholder="email" name="email" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row">
                                                            <label className="col-md-3 label-control" htmlFor="eventRegInput5">Contact Number</label>
                                                            <div className="col-md-9">
                                                                <input type="tel" id="eventRegInput5" className="form-control" name="contact" placeholder="contact number" />
                                                            </div>
                                                        </div>
                                                        <div className="form-group row last">
                                                            <label className="col-md-3 label-control">Existing Customer</label>
                                                            <div className="col-md-9">
                                                                <div className="input-group col-md-9">
                                                                    <label className="d-inline-block custom-control custom-radio ml-1">
                                                                        <input type="radio" name="customer" className="custom-control-input" />
                                                                        <span className="custom-control-indicator" />
                                                                        <span className="custom-control-description ml-0">Yes</span>
                                                                    </label>
                                                                    <label className="d-inline-block custom-control custom-radio">
                                                                        <input type="radio" name="customer" defaultChecked className="custom-control-input" />
                                                                        <span className="custom-control-indicator" />
                                                                        <span className="custom-control-description ml-0">No</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-actions center">
                                                        <button type="button" className="btn btn-warning mr-1">
                                                            <i className="ft-x" /> Cancel
                                                        </button>
                                                        <button type="submit" className="btn btn-primary">
                                                            <i className="la la-check-square-o" /> Save
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </section>
                        {/* // Basic form layout section end */}
                    </div>
                </div>
            </div>

            <Footer />

        </>


    )
}

export default Psingledata